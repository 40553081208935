:root {
  --waveform-height: 64px;
}

/* Style for the song element container */
.song-list {
  display: flex; /* Flexbox for inline alignment */
  justify-content: center; /* Space between image and player */
  width: 100%; /* Full width of the page */
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}


/* Style for the song element container */
.song-element {
  display: flex; /* Flexbox for inline alignment */
  justify-content: space-between; /* Space between image and player */
  align-items: center;
  background-color: #2F2F2F; /* Background color */
  border-radius: 25px; /* Rounded border */
  padding: 8px; /* Inner padding */
  width: 100%; /* Full width of the page */
  max-width: 600px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Optional shadow for depth */
  margin-bottom: 30px;
}

/* Style for the song art (image) */
.song-art {
  width: 224px; /* waveform(64px + 10px margins) + player-stats(20px + 20px padding) + artist-element(60px) */ /* Image takes 30% of parent */
  height: 224px;
  border-radius: 25px; /* Rounded corners */
  overflow: hidden; /* Ensure rounded corners work on image */
  padding: 8px; /* Padding around image */
  display: flex;
  flex-direction: column; /* Stack elements vertically */
}

.song-artist {
  width: 60px; /* Image takes 30% of parent */
  border-radius: 100px; /* Rounded corners */
  overflow: hidden; /* Ensure rounded corners work on image */
  padding: 0px; /* Padding around image */
}

/* Style for the song player */
.song-player {
  width: 60%; /* The remaining 70% of the div */
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  justify-content: space-evenly;
  padding: 10px 16px 10px 0px; /* top, left, bot, right */
  /* height: 174px; */ /* waveform(64px + 10px margins) + player-stats(20px + 20px padding) + artist-element(60px) */
  height: 224px;
}

/* Style for the song element container */
.artist-element {
    display: flex; /* Flexbox for inline alignment */
    justify-content: flex-end;
    width: 100%; /* Full width of the page */
    height: 60px;
}

.player-stats
{
  display: flex;                /* Enable flexbox */
  justify-content: space-between; /* Space items between, placing the first on the left and the last on the right */
  align-items: center;          /* Center items vertically */
  width: 325px;  /* player-row2-col1(150px) + player-row2-col2(175px) */           /* Make sure it uses the full width of the parent */
  padding: 10px 10px 10px 0;               /* Optional padding for aesthetics */
  height: 20px;
}

.waveform
{
  display: flex; 
  flex-direction: column;
  margin: 5px 0px;
  height: 64px;
  width: 100%;
  justify-content: center;/* Center items horizontally */
}

.artist-element div{
    display: flex; /* Flexbox for inline alignment */
    justify-content: flex-end;
    flex-direction: column;
    justify-content: center;
    padding-right: 10px;
}

.artist-element div h4{
    text-align: right;
}



.audio-controller {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;

  width: 100%;
  max-width: 1000px;
  padding: 10px;
}

.audio-controller input[type="range"] {
  width: 80%; /* Adjust as needed */
  margin-top: 10px;
}

.waveform-container {
  width: 100%;
  max-width: 1000px;
  padding-bottom: 56.25%; /* Maintain aspect ratio */
  overflow: hidden;
}



.loading-waveform {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 00%);
  background-color: rgba(0, 0, 0, 0.5); /* Optional: dark overlay for readability */
  color: white;
  padding: 10px;
  border-radius: 5px;
  z-index: 1; /* Ensure it appears above the waveform */
  pointer-events: none; /* Allows clicks to pass through to the waveform */
}

.player-timestamp
  {
    font-size: 10px;
  }

.player-btn-ico, .player-vol-ico
{
  font-size: 25px;
}


/* Smaller height on larger screens */
@media (min-width: 1024px) {
  .waveform-container .wavesurfer {
    height: 80px; /* Decrease height for desktop */
  }
}

/* Taller height on mobile */
@media (max-width: 768px) {
  .waveform-container .wavesurfer {
    height: 120px; /* Increase height for mobile */
  }
}

@media (max-width: 538px)
{
  .player-timestamp
  {
    font-size: 8px;
  }
  .player-btn-ico, .player-vol-ico
  {
    font-size: 15px;
  }
  .player-row2-col2
  {
    width: 150px !important;
  }
}

@media (max-width: 576px)
{
.song-art {
  width: 174px; /* waveform(64px + 10px margins) + player-stats(20px + 20px padding) + artist-element(60px) */ /* Image takes 30% of parent */
  height: 174px;
}
.song-player
{
  height: 174px;
}
}

@media (max-width: 475px)
{
  .song-element {
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
    text-align: center; /* Center text */
  }

    /* Style for the song art (image) */
  .song-art
  {
    width: 100%; /* Image takes 30% of parent */
    height: 100%;
    border-radius: 25px; /* Rounded corners */
    overflow: hidden; /* Ensure rounded corners work on image */
    padding: 8px; /* Padding around image */

    display: flex;
    flex-direction: column; /* Stack elements vertically */
  }
  .song-artist
  {
    display: none;
  }
  .artist-element
  {
    justify-content: start;
  }
  .artist-element div
  {
    flex-direction: column-reverse;
    align-items: start;
  }
  .song-player{
    padding: 10px 15px 10px 15px;
    width: 100%;
  }
  .player-timestamp
  {
    font-size: 12px;
  }
}

@media (max-width: 330px)
{
  .player-stats
  {
    padding: 10px 0;
  }
  .player-btn
  {
    padding: 0 2px 0 0;
  }
  .player-row2-col2
  {
    width: 130px !important;
  }
}

.player-stats {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.player-vol-ico{
  padding: 0 5px 0 0;
  color: var(--ss-font-color);
}

.player-btn button
{
  background: unset;
  border: unset;
}



.player-row2-col1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 90px;
}

.player-row2-col2 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 175px;
}








/* === range theme and appearance === */
input[type="range"] {
	font-size: 1.5rem;
	width: 12.5em;
}

input[type="range"] {
	color: #ef233c;
	--thumb-height: 12px;
	--track-height: 0.125em;
	--track-color: rgba(0, 0, 0, 0.2);
	--brightness-hover: 180%;
	--brightness-down: 80%;
	--clip-edges: 0.125em;
}

/* === range commons === */
input[type="range"] {
	position: relative;
	background: #fff0;
	overflow: hidden;

  color: var(--ss-font-color);
	--track-color: var(--ss-background-color: #212121;);
}

input[type="range"]:active {
	cursor: grabbing;
}

input[type="range"]:disabled {
	filter: grayscale(1);
	opacity: 0.3;
	cursor: not-allowed;
}

/* === WebKit specific styles === */
input[type="range"],
input[type="range"]::-webkit-slider-runnable-track,
input[type="range"]::-webkit-slider-thumb {
	-webkit-appearance: none;
	transition: all ease 100ms;
	height: var(--thumb-height);
}

input[type="range"]::-webkit-slider-runnable-track,
input[type="range"]::-webkit-slider-thumb {
	position: relative;
}

input[type="range"]::-webkit-slider-thumb {
	--thumb-radius: calc((var(--thumb-height) * 0.5) - 1px);
	--clip-top: calc((var(--thumb-height) - var(--track-height)) * 0.5 - 0.5px);
	--clip-bottom: calc(var(--thumb-height) - var(--clip-top));
	--clip-further: calc(100% + 1px);
	--box-fill: calc(-100vmax - var(--thumb-width, var(--thumb-height))) 0 0
		100vmax currentColor;

	width: var(--thumb-width, var(--thumb-height));
	background: linear-gradient(currentColor 0 0) scroll no-repeat left center /
		50% calc(var(--track-height) + 1px);
	background-color: currentColor;
	box-shadow: var(--box-fill);
	border-radius: var(--thumb-width, var(--thumb-height));

	filter: brightness(100%);
	clip-path: polygon(
		100% -1px,
		var(--clip-edges) -1px,
		0 var(--clip-top),
		-100vmax var(--clip-top),
		-100vmax var(--clip-bottom),
		0 var(--clip-bottom),
		var(--clip-edges) 100%,
		var(--clip-further) var(--clip-further)
	);
}

input[type="range"]:hover::-webkit-slider-thumb {
	filter: brightness(var(--brightness-hover));
	cursor: grab;
}

input[type="range"]:active::-webkit-slider-thumb {
	filter: brightness(var(--brightness-down));
	cursor: grabbing;
}

input[type="range"]::-webkit-slider-runnable-track {
	background: linear-gradient(var(--track-color) 0 0) scroll no-repeat center /
		100% calc(var(--track-height) + 1px);
}

input[type="range"]:disabled::-webkit-slider-thumb {
	cursor: not-allowed;
}

/* === Firefox specific styles === */
input[type="range"],
input[type="range"]::-moz-range-track,
input[type="range"]::-moz-range-thumb {
	appearance: none;
	transition: all ease 100ms;
	height: var(--thumb-height);
}

input[type="range"]::-moz-range-track,
input[type="range"]::-moz-range-thumb,
input[type="range"]::-moz-range-progress {
	background: #fff0;
}

input[type="range"]::-moz-range-thumb {
	background: currentColor;
	border: 0;
	width: var(--thumb-width, var(--thumb-height));
	border-radius: var(--thumb-width, var(--thumb-height));
	cursor: grab;
}

input[type="range"]:active::-moz-range-thumb {
	cursor: grabbing;
}

input[type="range"]::-moz-range-track {
	width: 100%;
	background: var(--track-color);
}

input[type="range"]::-moz-range-progress {
	appearance: none;
	background: currentColor;
	transition-delay: 30ms;
}

input[type="range"]::-moz-range-track,
input[type="range"]::-moz-range-progress {
	height: calc(var(--track-height) + 1px);
	border-radius: var(--track-height);
}

input[type="range"]::-moz-range-thumb,
input[type="range"]::-moz-range-progress {
	filter: brightness(100%);
}

input[type="range"]:hover::-moz-range-thumb,
input[type="range"]:hover::-moz-range-progress {
	filter: brightness(var(--brightness-hover));
}

input[type="range"]:active::-moz-range-thumb,
input[type="range"]:active::-moz-range-progress {
	filter: brightness(var(--brightness-down));
}

input[type="range"]:disabled::-moz-range-thumb {
	cursor: not-allowed;
}
