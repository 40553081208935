:root {
  --ss--temp-card-height: 550px;
}

.App {
  min-height: 80vh
}

body {
  background-color: var(--ss-background-color);
  color: #aaa;
  margin:0;
  padding:0;
}

html {
  height:100%;
  margin:0;
  padding:0;
}

.center
{
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: initial;
  align-items: center;
}

.hr-short {
  width: 30%; /* Expand to 30% of the page width */
  margin-left: 0; /* Left justify */
  border: 1px solid #444; /* Adjust color as needed */
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.overlay img {
  width: 50%;
  /*min-width: 700px;*/ /* was overflowing width so disabled for now */
  height: auto;
}

.contact-source
{
  color: inherit;
  text-decoration: none;
  font-weight: 400;
  font-size: inherit;
  line-height: inherit;
}

.contact-icon
{
  padding: 0px 5px 0px 0px;
}





/* Background video adjustment */
.background-video {
  position: absolute;
  top: 0; /* Ensure it starts from the top */
  right: 0;
  bottom: 0;
  z-index: -1;
}

@media (min-aspect-ratio: 16/9) {
  .background-video {
    width: 100vw;
    height: auto;
  }
}

@media (max-aspect-ratio: 4/3) {
  .background-video {
    width: auto;
    height: 100vh;
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: unset;
  align-items: center;
  min-height: 90vh; /* Adjust lower hero video spacing*/
  background: linear-gradient(to bottom, transparent 50%, var(--ss-background-color) 90%);
}

.content h1 {
  font-size: clamp(3rem, 10vw, 8rem);
  font-weight: 600;
  -webkit-text-stroke: 2px rgba(168, 239, 255, 1);
  color: transparent;
  transition: all 0.5s ease;
}

.info
{
  max-width: 1200px; /* Restrict width for large screens */
  margin: 0 auto; /* Center the content */
  padding: 0 5%; /* Add 5% padding on the sides for large screens */
}

ul.info {
  font-size: 0.9rem; /* Slightly smaller than the main text */
  text-align: left; /* Align the list items to the left */
  padding-left: 1.5rem; /* Indent the list (around a tab's width) */
  line-height: 1.5; /* Keep line height comfortable for readability */
}

ul.info li {
  margin-bottom: 0.75rem; /* Add space between each list item */
}

.homepage-list
{
  margin-bottom: 30px;
}

.btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 50px;
  border-radius: 5px;
  background: transparent;
  overflow: hidden;
  transition: all 0.3s ease-in;
  margin: 30px 0;
}

.btn:hover {
  transform: translateY(-3px);
  transform: scale(1.03);
}

.cta_btn {
  content: "";
  background-color: white;
  color: black; 
  text-decoration: none; /* Remove underline */
  font-weight: 600;      /* Set font weight to 600 */
}

.discord_btn {
  content: "";
  background-color: #7289da;
  color: #ffffff; 
  text-decoration: none; /* Remove underline */
  font-weight: 600;      /* Set font weight to 600 */
}

.video-container .overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  text-align: center;
  
}

.video-container .overlay img {
  max-width: 100%; /* Ensure the logo is responsive */
  height: auto;
}



.hero-video-container video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100vh;
  min-width: 100%;
  min-height: 100%;
  transform: translate(-50%, -50%);
  object-fit: cover; /* Ensure the video covers the container */
  z-index: -10;
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh + 1px);
  background-color: rgba(15, 17, 34, 0.7); /* Red color at 50% opacity */
  pointer-events: none; /* Ensure overlay does not block video controls */
  z-index: -5;
}





.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--ss-accent-color);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #aaa;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}











@media (max-width: 700px) { /* MOBILE VIEW */
  .overlay img {
    width: 100%;
    /*min-width: 700px;*/ /* was overflowing width so disabled for now */
    height: auto;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: unset;
    align-items: center;
    min-height: 100vh; /* Adjust lower hero video spacing*/
  }

  .info {
    padding: 0 20px; /* Reduce padding on smaller screens */
  }
}


.hero-logo
{
  margin: 20vh 0;
  width: 700px !important;
  height: auto;
}
@media (max-width: 800px) {
  .hero-logo {
    width: 500px !important;
  }
}
@media (max-width: 540px) {
  .hero-logo {
    width: 300px !important;
  }
}
@media (max-width: 380px) {
  .hero-logo {
    width: 250px !important;
  }
}







.video-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.video-wrapper {
  position: relative;
  width: 100%;
  max-width: 1000px; /* Max width for larger screens */
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio (height / width = 9 / 16 * 100) */
  height: 0;
  overflow: hidden;
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.homeThumbnail {
  width: 100%; /* Makes the image span the full width of its parent */
  height: auto; /* Maintains aspect ratio */
  object-fit: cover; /* Ensures the image covers the area without stretching */
}



/* Breakpoints for scaling */
@media (max-width: 768px) {
  .video-wrapper {
    max-width: 100%; /* Full width for smaller devices */
  }
}

/* Optional breakpoint for large screens */
@media (min-width: 1024px) {
  .video-wrapper {
    max-width: 800px; /* Set a more reasonable max-width for large screens */
    padding-bottom: 40%; /* 16:9 Aspect Ratio (height / width = 9 / 16 * 100) */
  }
}

/* Optional breakpoint for large screens */
@media (min-width: 1580px) {
  .video-wrapper {
    max-width: 800px; /* Set a more reasonable max-width for large screens */
    padding-bottom: 500px; /* 16:9 Aspect Ratio (height / width = 9 / 16 * 100) */
  }
}
