.gallery-container-OLD {
    display: flex;
    flex-wrap: wrap; /* Ensure items wrap within container */
    justify-content: center; /* Center the items within the container */
    gap: 10px; /* Add space between items */
    max-width: 100%; /* Prevent container from exceeding viewport width */
    margin: 0 auto; /* Center the container horizontally */
    overflow-x: hidden; /* Hide any overflow content horizontally */
}

.gallery-container_OLD img {
    max-width: 100%;
    height: auto;
    display: block; /* Ensure images respect container dimensions */
    object-fit: cover; /* Ensure the image fills its container */
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Optional shadow for depth */
    transition: all 0.3s ease-in;
}

.gallery-container_OLD a {
    margin: 10px; /* Space between items */
    display: block;
    flex-grow: 1;
    flex-basis: calc(33.333% - 10px); /* Allow for 3 images per row with space between them */
    max-width: calc(33.333% - 10px); /* Limit each image to a third of the container width */
    align-content: center;
}






/* Ensure .large-image takes precedence */
.gallery-container a.large-image {
    flex: 2 2 calc(50% - 10px); /* Large images take up twice the space */
    max-width: calc(50% - 10px); /* Ensure the large image's max width is 50% of container */
}

.gallery-container a.large-image img {
    width: 100%; /* Ensure the image inside takes up the full width */
    height: auto;
    border-radius: 10px; /* Keep the same border-radius */
    object-fit: cover; /* Ensure images are cropped to fit nicely */
}

.gallery-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center all rows */
    gap: 10px; /* Space between images */
    padding: 0 5%;
}

.gallery-container a {
    flex: 1 1 calc(25% - 10px); /* Default: 25% width minus the gap */
    max-width: calc(25% - 10px); /* Limit max width to 25% of the container */
    margin-bottom: 10px; /* Add bottom margin */
}

.gallery-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Optional shadow for depth */
    transition: all 0.3s ease-in;
}

.gallery-container img.loaded {
    opacity: 1; /* Fade-in effect after image loads */
}

.gallery-container img:hover {
    transform: translateY(-3px);
    transform: scale(1.03);
    opacity: 1;
    border-radius: 10px; /* Keep the border-radius intact on hover */
}

/* Fancybox-specific styling, if needed */
.fancybox__container {
    z-index: 9999; /* Ensure Fancybox pops over everything */
}

/* Mobile responsiveness for smaller screens */
@media (max-width: 768px) {
    .gallery-container a {
        flex: 1 1 calc(50% - 10px); /* On smaller screens, each image will take 50% width */
        max-width: calc(50% - 10px);
    }
}

@media (max-width: 480px) {
    .gallery-container a {
        flex: 1 1 calc(100% - 10px); /* On very small screens, each image takes full width */
        max-width: calc(100% - 10px);
    }
}