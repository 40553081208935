.footerLinks {
    color: var(--ss-font-color);
    text-decoration: none;
  }
  
  .footerLinks:hover {
    text-decoration: underline;
  }
  
  .centerDiv {
    text-align: center;
  }

  .footerLinks i
  {
    font-size: 15px;
    padding: 0 4px;
  }
  