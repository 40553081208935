.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  .close-popup {
    position: absolute; /* Position absolute for the close button */
    top: 10px; /* Space from the top */
    right: 10px; /* Space from the right */
    border: none; /* Remove default border */
    background: transparent; /* Transparent background */
    cursor: pointer; /* Pointer cursor */
    font-size: 20px; /* Size of the close icon */
  }

  .contacts {
    margin-top: 15px; /* Space above contacts */
  }
  
  .contact {
    display: flex; /* Flexbox for icon and source */
    align-items: center; /* Center items vertically */
    margin-bottom: 10px; /* Space between contacts */
  }

  .team-container {
    display: flex; /* Use flexbox */
    flex-wrap: wrap; /* Allow items to wrap to the next line */
    justify-content: center; /* Center the items */
    align-items: flex-start; /* Align items to the start vertically */
    margin: 20px; /* Margin around the container */
    padding: 20px; /* Padding inside the container */
  }

  .artist-popup {
    position: fixed; /* Fixed positioning to stay centered on screen */
    top: 0; /* Align to the top */
    left: 0; /* Align to the left */
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    background-color: rgba(200, 200, 200, 0.1); /* White tint with opacity for blurring */
    backdrop-filter: blur(10px); /* Blur effect */
    display: flex; /* Use flexbox to center content */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    z-index: 1000; /* Ensure it is on top of other elements */
  }

  .popup-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.5); /* Add a slight tint */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Make sure it's above other elements */
  }

  .artist-card {
    background-color: var(--ss-accent-color);
    border-radius: 15px;
    box-shadow: var(--ss-drop-shadow);
    margin: 10px;
    padding: 20px 15px;
    position: relative;
    width: 100%; /* Responsive width */
    max-width: 300px; /* Maximum width */
    height: var(--ss--temp-card-height);
    max-height: var(--ss--temp-card-height); /* Maximum height */
    text-align: center; /* Center text */
  
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .artist-card-popup {
    background-color: var(--ss-accent-color);
    border-radius: 15px;
    box-shadow: var(--ss-drop-shadow);
    margin: 10px;
    padding: 20px 15px;
    position: relative;
    width: 100%; /* Responsive width */
    min-width: 300px; /* Maximum width */
    width: 80%;
    max-width: 400px;
    height: var(--ss--temp-card-height) + 1000px;
    /*max-height: var(--ss--temp-card-height + 0);*/       /* Maximum height */
    text-align: center; /* Center text */

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
  }

  .popup-content img, .artist-card img, .artist-card-popup img{
    max-width: 100%; /* Image contained within its parent */
    height: auto; /* Maintain aspect ratio */
    border-radius: 30px; /* Optional: rounded corners for the image */
  }
  .artist-name
  {
    margin: 0px 0px;
  }

  .artist-title
  {
    margin: 10px 0px;
  }

  .artist-card .contacts
  {
    width: auto;
  }

  .popup-content {
    background-color: var(--ss-accent-color);
    border-radius: 15px;
    box-shadow: var(--ss-drop-shadow);
    padding: 20px;
    position: relative;
    width: 80%; /* Responsive width */
    max-width: 500px; /* Maximum width */
  }












  /* TESTING BELOW ON STATIC TEST Team.TSX page */
  
  .team-section {
  text-align: center;
  padding: 40px;
}

.team-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.team-member {
  flex-basis: 30%; /* Adjust as needed */
  margin: 20px;
  text-align: center;
}

.team-photo {
  width: 100%;
  max-width: 200px; /* Limit image size */
  border-radius: 50%;
  margin-bottom: 20px;
}

/* Breakpoint for mobile screens */
@media (max-width: 768px) {
  .team-member {
    flex-basis: 100%; /* Stack members vertically on smaller screens */
  }
}
