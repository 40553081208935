@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

:root {
  --sidePadding: 25px;
}

body {
  font-family: "Poppins", sans-serif;
  background-repeat: no-repeat;
  min-height: 100vh;

  overflow-x: hidden !important;
  margin: 0; /* Remove any default margin that might cause overflow */
}

/* Base styling for NavBar container */
.navbar-container-sticky {
  width: 100%;
  z-index: 5;
  display: flex;
  flex-direction: column;
  transition: background-color 0.3s ease;
  position: sticky;
  top: 0;
}
.navbar-container {
  width: 100%;
  z-index: 5;
  display: flex;
  flex-direction: column;
  transition: background-color 0.3s ease;
}

/* Transparent theme */
.navbar-container.transparent, .navbar-container-sticky.transparent{
  background: transparent;
  color: #fff; /* Adjust text color as needed */
}

/* Solid theme */
.navbar-container.solid, .navbar-container-sticky.solid {
  background: #444444; /* Adjust color as needed */
  color: #fff; /* Adjust text color as needed */
}

/* Top row styling for social media icons */
.navbar-top-row {
  display: flex;
  justify-content: flex-end; /* Distribute icons evenly */
  padding: 8px var(--sidePadding); /* Adjust as needed */
  gap: 12px; /* Space between elements */
  z-index: 10;
}

/* Bottom row styling for logo and menu */
.navbar-bottom-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px var(--sidePadding);
}

/* Styling for social media buttons */
.btn-social {
  font-size: 1.5rem;
  color: inherit;
  text-decoration: none;
  transition: color 0.3s ease;
}

.btn-social:hover {
  color: var(--ss-navbar-btn-hover) /* Adjust hover color as needed */
}



/* Logo styling */
.logo {
  width: 100px; /* Adjust as needed */
  color: var(--ss-font-color);
}


.nav-item {
  position: relative;
  color: inherit; /* Use the current color */
  font-size: 1rem;
  font-weight: 500;
  text-decoration: none;
  transition: color 0.5s ease;
}

.nav-item:hover {
  color: var(--ss-navbar-btn-hover) /* Hover color */
}

.nav-item::after {
  content: "";
  position: absolute;
  bottom: -0.3rem;
  left: 50%;
  width: 0;
  height: 0.15rem;
  transform: translateX(-50%);
  background-color: var(--ss-navbar-btn-hover);
  transition: all 0.5s ease;
}

.nav-item:hover::after {
  width: 100%;
}

/* Icons styling */
.icons {
  position: absolute;
  right: 5%;
  font-size: 30px;
  color: #fff;
  padding: 0px 0px 15px 0px;
  cursor: pointer;
  display: none;
}

#check {
  display: none;
}

/* Menu styling */
.navbar-menu {
  display: flex;
  justify-content: flex-end; /* Align items to the right */
  gap: 40px; /* Space between menu items */
  
}

.navbar-menu .nav-item:last-of-type {
  padding-right: 0;
}

/* Mobile styles for responsive design */
@media (max-width: 700px) {
  .navbar-top-row {
    flex-direction: row;
    align-items: center;
  }

  .navbar-bottom-row {
    flex-direction: row;
    align-items: center;
    justify-content: space-between; /* Ensure logo and icons are spaced correctly */

  }

  .navbar-menu .nav-item:first-of-type {
    margin-top: 20vh;
  }

  .navbar-container-sticky {
    height: 8vh;
    z-index: 100px;
  }

  .icons {
    display: inline-flex;
    z-index: 10;
  }

  

  #check:checked ~ .icons #menu-icon {
    display: none;
  }

  .icons #close-icon {
    display: none;
  }

  #check:checked ~ .icons #close-icon {
    display: block;
  }

  .navbar-menu-backup {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 0;
    flex-direction: column;
    gap: 0px;
    background: #44444450;
    backdrop-filter: blur(50px);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
    transition: all 0.5s ease;
  }

  .navbar-menu-WORKING {
    position: absolute;
    top: -55px;
    left: 0;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
    align-items: end;
    justify-content: start;
    gap: 0px;
    background: #44444450;
    backdrop-filter: blur(50px);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease;
    transform: translateX(100%); /* Initially shifted off the page */
  }

  .navbar-menu {
    position: absolute;
    top: -55px;
    right: 0; /* Anchor the menu to the right */
    width: 0; /* Initially hidden */
    height: 110vh; /* Slightly adjusted height to account for scrolling below navbar */
    flex-direction: column;
    align-items: end;
    justify-content: start;
    gap: 0px;
    background: #44444450;
    backdrop-filter: blur(50px);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
    transition: width 0.4s ease, visibility 0.4s ease; /* Smooth transition for width */
    overflow: hidden; /* Ensure contents don't overflow */
    visibility: hidden; /* Hidden by default */
  }

  .navbar-wrapper-UNUSED
  {
    overflow-x: hidden !important;
    width: 100%; /* Ensure the wrapper is full width CODY HERE START HERE!!!!!!!*/
    position: relative; /* Make sure it doesn’t affect layout unexpectedly THIS LINE BREAKS POP OUT*/
  }



  #check:checked ~ .navbar-menu {
    width: 100vw; /* Expands to full page width */
    visibility: visible; /* Make it visible */
  }

  .navbar-menu .nav-item {
    display: block;
    font-size: 1.1rem;
    margin: 20px 0;
    text-align: center;
    transform: translateX(100px);
    opacity: 0;
    transition: all 0.15s ease;
    padding-right: var(--sidePadding);
  }

  .navbar-menu .nav-item:hover::after {
    width: auto;
  }

  .navbar-menu .nav-item:last-of-type {
    padding-right: var(--sidePadding);
  }

  #check:checked ~ .navbar-menu .nav-item {
    transform: translateX(0);
    opacity: 1;
    transition-delay: calc(0.1s * var(--i));
  }
}

@media (max-width: 450px) {
  .btn {
    width: 130px;
  }

  .light:after {
    width: 120px;
  }
}
